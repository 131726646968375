<template>
  <div class="relative bg-gray-50 overflow-hidden">
    <div class="hidden sm:block sm:absolute sm:inset-y-0 sm:h-full sm:w-full">
      <div class="relative h-full max-w-screen-xl mx-auto">
        <svg
          class="absolute right-full transform translate-y-1/4 translate-x-1/4 lg:translate-x-1/2"
          width="404"
          height="784"
          fill="none"
          viewBox="0 0 404 784"
        >
          <defs>
            <pattern
              id="svg-pattern-squares-1"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect
                x="0"
                y="0"
                width="4"
                height="4"
                class="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect width="404" height="784" fill="url(#svg-pattern-squares-1)" />
        </svg>
        <svg
          class="absolute left-full transform -translate-y-3/4 -translate-x-1/4 md:-translate-y-1/2 lg:-translate-x-1/2"
          width="404"
          height="784"
          fill="none"
          viewBox="0 0 404 784"
        >
          <defs>
            <pattern
              id="svg-pattern-squares-2"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect
                x="0"
                y="0"
                width="4"
                height="4"
                class="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect width="404" height="784" fill="url(#svg-pattern-squares-2)" />
        </svg>
      </div>
    </div>

    <section x-data="{ open: false }" class="relative pt-6">
      <section class="text-gray-700 body-font text-center mx-0">
        <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col">
          <div
            class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center"
          >
            <p class="text-indigo-700 bold">Compliance Online</p>

            <h1
              class="title-font sm:text-4xl text-3xl text-center mb-4 font-medium text-gray-900"
            >
              {{ post.Title }}
            </h1>
            <!-- component -->
            <div>
              <div
                class="text-sm inline-flex items-center font-bold leading-sm uppercase px-3 py-1 bg-co-500 text-white rounded-full"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-archive mr-2"
                >
                  <polyline points="21 8 21 21 3 21 3 8"></polyline>
                  <rect x="1" y="3" width="22" height="5"></rect>
                  <line x1="10" y1="12" x2="14" y2="12"></line>
                </svg>
                OTIMIZADO PARA E-COMMERCES, MARKETPLACES, SAAS, APPS E MAIS
              </div>

              <router-link
                to="/app"
                class="ml-4 text-xs inline-flex items-center font-bold leading-sm uppercase px-3 py-1 rounded-full bg-white text-gray-700 border"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  width="16"
                  height="16"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    class=""
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                Começar
              </router-link>
            </div>
            <p class="leading-2 text-gray-600 mt-4 text-sm">
              Não sabe se precisa dos documentos?
              <router-link
                to="/artigos/o-que-e-lgpd-e-por-que-sua-empresa-deve-se-adequar"
                class="text-sm underline"
                >Ler sobre a importância de estar de acordo com a
                LGPD</router-link
              >
            </p>
            <div></div>
            <div class="w-full mx-auto prose page-post pt-10">
              <vue-showdown
                v-if="post.Content != null"
                class=""
                :markdown="post.Content"
                flavor="vanilla"
                :options="{ emoji: true }"
              />
            </div>
            <div class="mt-3 space-x-0 md:space-x-2 mb-4 md:mb-8">
              <a
                class="btn btn-primary bg-co-500 hover:bg-co-500 hover:text-white text-white btn-lg inline-flex justify-center items-center w-full sm:w-auto mb-2 sm:mb-0"
                href="/app"
              >
                Começar
                <svg
                  class="ml-1 w-4 h-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </section>
    </section>

    <Features></Features>
    <section class="container px-0 py-12 mx-auto sm:px-4">
      <div
        class="grid items-center grid-cols-1 gap-10 px-4 py-6 overflow-hidden text-white bg-co-500 border-pink-100 rounded-none card card-body sm:rounded-lg md:px-10 md:grid-cols-5 lg:gap-0"
      >
        <div class="col-span-1 md:col-span-3">
          <h2
            class="mb-3 font-serif text-2xl font-normal leading-tight lg:text-3xl"
          >
            Durma em paz sabendo que seu empresa está de acordo com a
            legislação.
          </h2>
          <p class="mb-6 text-sm font-semibold lg:text-base">
            Nossos documentos seguem todas as diretrizes pela LGPD, bem como as
            boas práticas de mercados nacionais e internacionais.
          </p>
          <a href="/app" class="w-full shadow btn btn-white btn-lg sm:w-auto"
            >Comece agora mesmo</a
          >
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import router from '@/router';
import Features from '@/components/Home/HomeCO/Features';
import CTA from '@/components/Home/HomeET/CTA';

export default {
  name: 'PageCO',
  components: { Features, CTA },
  data() {
    return {
      pagina: [],
      content: {},
    };
  },
  created() {
    this.showPagina(this.home);
  },
  computed: {
    ...mapState('pagina', ['post']),
  },
  methods: {
    ...mapActions('pagina', ['getPagina']),
    async showPagina(system) {
      const id = this.$route.params.id;

      await this.getPagina({ sistema: system, id: id }).then((x) => {
        this.content = this.post.Content;
      });
    },
  },

  metaInfo() {
    let title = this.post.Title;
    let description = this.post.Summary;
    const url = document.URL;
    if (url.includes('compliance')) {
      this.home = 'complianceOnline';
    } else if (url.includes('easy')) {
      this.home = 'easyTermos';
    }
    process.env.VUE_APP_SYSTEM !== null && url.includes('local')
      ? (this.home = process.env.VUE_APP_SYSTEM)
      : '';

    return {
      title: title,
      meta: [
        {
          name: 'description',
          content: description,
        },
      ],
    };
  },
};
</script>

<style scoped></style>
