<template>
  <div class="mb-4 text-sm font-medium text-black">
    <p class="flex items-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        class="w-5 h-5 text-et-500 mr-2"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="4"
          d="M5 13l4 4L19 7"
        />
      </svg>
      Todos os termos necessários para o seu {{ text }}
    </p>
    <div class="flex mt-2">
      <p class="flex items-center mr-3">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          class="w-5 h-5 text-et-500 mr-2"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="4"
            d="M5 13l4 4L19 7"
          />
        </svg>
        Relatórios de Ocorrência
      </p>
      <p class="flex items-center mr-3">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          class="w-5 h-5 text-et-500 mr-2"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="4"
            d="M5 13l4 4L19 7"
          />
        </svg>
        Termos de Uso
      </p>
    </div>
    <div class="flex mt-2">
      <p class="flex items-center mr-3">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          class="w-5 h-5 text-et-500 mr-2"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="4"
            d="M5 13l4 4L19 7"
          />
        </svg>
        Política de Privacidade
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'FeaturesOnPolicyPage',
  props: {
    text: {
      type: String,
    },
  },
};
</script>
