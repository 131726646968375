<template>
  <div class="relative overflow-hidden">
    <section class="relative">
      <section class="text-gray-700 bg-green-200">
        <div
          class="container mx-auto flex px-5 pt-48 pb-20 md:flex-row flex-col"
        >
          <div
            class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-2 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center"
          >
            <h1
              class="mt-4 md:max-w-3xl text-left text-xl font-extrabold text-gray-900 sm:mt-5 sm:leading-no leading-none ne sm:text-5xl lg:mt-6 lg:text-3xl xl:text-4xl"
            >
              {{ post.Title }}
            </h1>
            <!-- component -->

            <p class="leading-2 text-gray-600 my-4 text-sm">
              Não sabe se precisa dos documentos?
              <router-link
                to="/artigos/o-que-e-lgpd-e-por-que-sua-empresa-deve-se-adequar"
                class="text-sm underline"
                >Ler sobre a importância de estar de acordo com a
                LGPD</router-link
              >
            </p>
            <FeaturesOnPolicyPage text="sistema" />
            <div class="">
              <a
                href="/app"
                class="btn bg-et-500 hover:scale-105 duration-150 transition hover:bg-et-600 mt-6 text-white rounded-full mr-2 btn-md tracking-tighter px-6 py-3 mx-auto leading-2"
              >
                Gerar minha política de privacidade sem custos
              </a>
              <router-link
                :to="$route.path + '/exemplo'"
                class="btn hover:scale-105 bg-gray-200 duration-150 transition hover:bg-gray-300 mt-6 text-gray-800 rounded-full mr-2 btn-md tracking-tighter px-6 py-3 mx-auto leading-2"
              >
                Ver exemplo
              </router-link>
            </div>
            <div class="w-full mx-auto prose page-post pt-10">
              <vue-showdown
                v-if="post.Content != null"
                class="w-full"
                :markdown="post.Content"
                flavor="vanilla"
                :options="{ emoji: true }"
              />
            </div>
          </div>

          <div
            class="lg:flex-grow md:w-1/2 top-0 md:items-start md:text-left md:mb-0 items-center text-center"
          >
            <lottie-animation
              path="img/drawkit-grape-animation-4-LOOP.json"
              class="hidden md:block"
              height="500"
              width="700"
              :speed="1"
            />
          </div>
        </div>
      </section>
    </section>
    <ForAllCompanies />

    <Features></Features>

    <FAQ></FAQ>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import router from '@/router';
import Features from '@/components/Home/HomeET/Features';
import CTA from '@/components/Home/HomeET/CTA';
import FeaturesOnPolicyPage from '@/views/Politicas/FeaturesOnPolicyPage';
import ForAllCompanies from '@/views/ForAllCompanies';
import FAQ from '@/components/Home/HomeET/FAQ';
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'; // import lottie-vuejs

export default {
  name: 'PageET',
  components: {
    FAQ,
    ForAllCompanies,
    FeaturesOnPolicyPage,
    Features,
    CTA,
    LottieAnimation,
  },
  data() {
    return {
      pagina: [],
      content: {},
    };
  },
  created() {
    this.showPagina(this.home);
  },
  computed: {
    ...mapState('pagina', ['post']),
  },
  methods: {
    ...mapActions('pagina', ['getPagina']),
    async showPagina(system) {
      const id = this.$route.params.id;

      await this.getPagina({ sistema: system, id: id }).then((x) => {
        this.content = this.post.Content;
      });
    },
  },

  metaInfo() {
    let title = this.post.Title;
    let description = this.post.Summary;
    const url = document.URL;
    if (url.includes('compliance')) {
      this.home = 'complianceOnline';
    } else if (url.includes('easy')) {
      this.home = 'easyTermos';
    }
    process.env.VUE_APP_SYSTEM !== null && url.includes('local')
      ? (this.home = process.env.VUE_APP_SYSTEM)
      : '';

    return {
      title: title,
      meta: [
        {
          name: 'description',
          content: description,
        },
      ],
    };
  },
};
</script>

<style scoped>
.prose {
  max-width: none !important;
}
</style>
