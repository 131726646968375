<template>
  <div v-if="home === 'easyTermos'">
    <PageET></PageET>
  </div>
  <div v-else-if="home === 'complianceOnline'">
    <PageCO></PageCO>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import router from '@/router';

import PageET from '@/components/Page/PageET';
import PageCO from '@/components/Page/PageCO';
export default {
  name: 'Page',
  components: { PageET, PageCO },
  data() {
    return {
      home: false,
    };
  },
  created() {
    const url = document.URL;
    if (url.includes('compliance')) {
      this.home = 'complianceOnline';
    } else if (url.includes('easy')) {
      this.home = 'easyTermos';
    }
    process.env.VUE_APP_SYSTEM !== null && url.includes('local')
      ? (this.home = process.env.VUE_APP_SYSTEM)
      : '';
  },
};
</script>

<style scoped></style>
